import React, { useState } from "react";
import CustomTabs from "../../components/common/CTabs";
import CInput from "../../components/common/CInput";
import { Theme } from "../../Theme";
import CTable from "../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../components/common/CAlert";
import { activeDeactiveService, getAllServices } from "../../services/service";
import _ from 'lodash';
import AlertModal from "../../components/common/AlertModal";
import { Switch } from "antd";
import CButton from "../../components/common/CButton";

const tabs = [
    { key: 'Services', label: 'Services' },
    { key: 'Vendor Details', label: 'Vendor Details' },
];

const ServicesList: React.FC = () => {
    const [userWillActive, setUserWillActive] = useState<{ show: boolean; status: boolean; id: number | null }>({
        show: false,
        status: false,
        id: null,
    });
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    console.log('params===>>>', params, location.state);

      const activeKey = searchParams.get('UserType') || 'Services';
      const pageNumber = Number(searchParams.get('PageNumber')) || 1;
      const pageSize = Number(searchParams.get('PageSize')) || 10;
      const search = searchParams.get('Search') || "";
  
      const handleTabChange = (key: string) => {
        if(key === 'Vendor Details'){
            navigate('/jive/viewVendor', { state: {  id: params.id } })
        }
        //   setSearchParams({ UserType: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
      };
  
      // Pagination handling
      const handleTableChange = (page: number, pageSize: number) => {
          setSearchParams({ageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
      };
  
      const handleSearchChange =  _.debounce((e:any) => {
          setSearchParams({PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
      },1000)


      const handleSwitchChange = (user: any) => {
        setUserWillActive({ show: true, status: user?.active, id: user?.id });
    };


    const columns: ColumnsType = [
        {
            title: t("ID"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("Service Name"),
            dataIndex: 'serviceName',
            key: 'serviceName',
        },
        {
            title: t("Category"),
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: t("Location"),
            dataIndex: 'serviceLocation',
            key: 'serviceLocation',
        },
        // {
        //     title: t("Number of Packages"),
        //     dataIndex: 'serviceCount',
        //     key: 'serviceCount',
        // },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                    <img
                        src={Theme.icons.icn_view_list}
                        alt="icn_user_add"
                        style={{ height: '20px' }}
                        onClick={() => navigate(`/jive/viewService/${record?.id}`)}
                    />
                        <Switch
                        checked={record?.active}
                        onChange={() => handleSwitchChange(record)}
                        className='mx-2'
                    />
                </div>
            ),
            key: 'action',
        },
    ];


    const { data, error, isLoading } = useQuery(
        ['AllServices', pageNumber, pageSize, search],
        () => getAllServices({ VendorFreelancerId: params?.id, PageNumber: pageNumber, PageSize: pageSize, Search: search}),
        {
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );

        const { mutate, isLoading: statusLoading } = useMutation(activeDeactiveService, {
        onMutate: async (newStatus: any) => {

            await queryClient.cancelQueries(['AllServices']);
            const previousData = queryClient.getQueryData(['AllServices']);
            return { previousData };
        },
        onError: (err: any, newStatus, context) => {
            queryClient.setQueryData(['AllServices'], context?.previousData);
            setAlert({ message: err?.message || t("Failed to update status"), type: 'error', visible: true });
        },
        onSettled: () => {
            queryClient.invalidateQueries(['AllServices']);
        },
        onSuccess: () => {
            setAlert({ message: t("Status Updated successfully!"), type: 'success', visible: true });
        },
    });

    const handleModalOk = () => {
        if (userWillActive.id !== null) {
            const newStatus = !userWillActive.status;
            console.log('max Val==+>>>', userWillActive);
            mutate({ id: userWillActive.id, Active: newStatus });
        }
        setUserWillActive({ show: false, status: false, id: null });
    };

    const handleModalCancel = () => {
        setUserWillActive({ show: false, status: false, id: null });
    };

    return (
        <>
          <div className='d-flex align-items-center mb-4'>
                    <img src={Theme.icons.icn_arrow_back} className='cursor-pointer' alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{location?.state?.record?.contactPersonName}</p>
                </div>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />

            <div className="d-flex justify-content-between align-items-center" >
                <p className="m-0 list-heading">{`${activeKey} List`}</p>
                <div className="d-flex justify-content-between">
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                     <CButton className="addUserBtn" onClick={() => navigate('/jive/createService', {state:{ id: params.id, vendorFreeLancerName: location?.state?.record?.contactPersonName}})}>
        <img src={Theme.icons.icn_add_service} alt="icn_user_add" style={{ height: '20px' }} />
            <p className="m-0">{t("Create Service")}</p>
        </CButton>
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger:false,
                    showQuickJumper:false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />

<AlertModal
                visible={userWillActive?.show}
                message={t(`Are you sure you want to ${userWillActive?.status ? 'deactivate' : 'activate'} this Service?`)}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default ServicesList;
