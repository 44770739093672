import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Switch, Card, Spin, Modal } from 'antd';
import { Theme } from '../../../Theme';
import CustomAlert from '../../../components/common/CAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import AlertModal from '../../../components/common/AlertModal';
import { useTranslation } from 'react-i18next';
import { changeVendorStatus, getVendorFreelancerById } from '../../../services/freelancer';
import { getAllParentCategory } from '../../../services/lov';
import CModal from '../../../components/common/CModal';



const weekdays = [
    { label: 'Mon', value: 'monday' },
    { label: 'Tue', value: 'tuesday' },
    { label: 'Wed', value: 'wednesday' },
    { label: 'Thu', value: 'thursday' },
    { label: 'Fri', value: 'friday' },
    { label: 'Sat', value: 'saturday' },
    { label: 'Sun', value: 'sunday' },
];

const ViewVendor: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { id } = location.state;
    const [selectedTab, setSelectedTab] = useState(1);
    const [days, setDays] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [previewImg, setPreviewImg] = useState("");
    const [visible, setVisible] = useState(false);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    console.log('day==>>>', days);


    const { data: vendorData, isLoading, error } = useQuery(
        ['VendorFreelancerById', id],
        () => getVendorFreelancerById({ Id: id }),
        {
            onSuccess: (data) => {
                setSwitchChecked(data?.data?.active);
            },
        }
    );

    let { businessName, personName, phoneNumber, businessAddress, websiteURL, email, serviceTypeId, profileImagePath, serviceTechnicalDescription, portfolioLink, socialMediaLinks, experience, eventType, bookingProcessDetail, bookingStartTime, bookingEndTime, commericalRegistrationNumber, vendorFreelancerCertifications, bankName, accountHolderName, accountNumber, cityName, countryName, contactPersonName, communicationMethod
    } = vendorData?.data || {};

    const FinalApprovals = [
        {
            id: 1,
            label: t("Vendor Information"),
            icon: Theme.icons.icn_user_company,
        },
        {
            id: 2,
            label: t("service_offerings"),
            icon: Theme.icons.icn_services,
        },
        {
            id: 3,
            label: t("portfolio_experience"),
            icon: Theme.icons.icn_portfolio,
        },
        {
            id: 4,
            label: t("availability_booking"),
            icon: Theme.icons.icn_booking,
        },
        {
            id: 5,
            label: t("certifications_licenses"),
            icon: Theme.icons.icn_license,
        },
        {
            id: 6,
            label: t("payment_billing"),
            icon: Theme.icons.icn_payment,
        },
        {
            id: 7,
            label: t("support_communication"),
            icon: Theme.icons.icn_support,
        },
    ];

    const { mutate, isLoading: statusLoading, isError } = useMutation(changeVendorStatus, {
        onSuccess: (response) => {
            if (response.succeeded) {
                setSwitchChecked(!switchChecked);
                setAlert({ message: 'Status Updated successfully!', type: 'success', visible: true });
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.message : 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSwitchChange = () => {
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        const newStatus = !switchChecked;
        const obj = {
            id,
            status: newStatus,
        };
        mutate(obj);
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (vendorData?.data) {
            let selectedDays: string[] = [];

            weekdays.forEach(day => {
                console.log('d==>', day);
                if (vendorData?.data[day.value]) {
                    selectedDays.push(day.label); // Push label (Mon, Tue, etc.) to the array
                }
            });
            setDays(selectedDays);
        }
    }, [])

    const handleTabClick = (tabId: number) => {

        setSelectedTab(tabId);

    };


    const previewImage = (file: any) => {
        console.log('my file===>>>', file);
        setPreviewImg(file);
        setVisible(true);
    }

    const { data: categoriesData, error: countryError, isLoading: isCategoriesLoading } = useQuery('categories', getAllParentCategory);
    const selectedCategories = categoriesData?.data?.filter((category: any) => serviceTypeId?.includes(category.id));


    return (
        <div style={{ color: Theme.colors.black }}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <img src={Theme.icons.icn_arrow_back} className='cursor-pointer' alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{t("Vendor Details")}</p>
                </div>
                <div className='d-flex align-items-center'>
                    {/* <CButton style={{ marginRight: '12px', backgroundColor: Theme.colors.success, borderColor: Theme.colors.success }}>Accept</CButton>
                    <CButton className='rejectBtn' style={{ colo: 'red' }}>Reject</CButton> */}
                    <p className='m-0 admin' style={{color: Theme.colors.black}}>{switchChecked ? 'Active' : 'Inactive'}</p>
                    <Switch checked={switchChecked} onChange={handleSwitchChange} className='mx-2' />
                </div>
            </div>
            <Card className='mt-3'>
                <p className='freelancer-heading m-0'>{t("Vendor Profile")}</p>
            </Card>
            <div className='row' style={{ paddingRight: '0.8rem' }}>
                <div className='col-lg-3'>
                    <Card className='mt-3' style={{ borderRadius: '16px' }}>
                        {FinalApprovals?.map((item) => (
                            <div
                                key={item.id}
                                className='d-flex align-items-center mt-2 w-100 approval-tab'
                                style={{ border: item?.id == selectedTab ? `1px solid ${Theme.colors.tertiary}` : `1px solid ${Theme.colors.gray}` }}
                                onClick={() => handleTabClick(item.id)}
                            >
                                <div
                                    className='d-flex align-items-center justify-content-center'
                                    style={{
                                        height: '28px',
                                        width: '28px',
                                        borderRadius: '8px',
                                        backgroundColor: item?.id === selectedTab ? "#FDF0D9" : Theme.colors.gray_light
                                    }}
                                >
                                    <img
                                        src={item.icon}
                                        alt={item.label}
                                        style={{
                                            height: '20px',
                                            // Use filter to change the color of the SVG when selected 
                                            filter: item?.id === selectedTab
                                                ? 'invert(74%) sepia(99%) saturate(1320%) hue-rotate(1deg) brightness(103%) contrast(101%)'
                                                : 'none'
                                        }}
                                    />
                                </div>
                                <p className='m-0 mx-2 admin'>{item.label}</p>
                            </div>
                        ))}
                    </Card>
                </div>
                <div className='col-lg-9 mt-3' style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px' }}>
                    <p className='user-name'>{FinalApprovals[selectedTab - 1]?.label}</p>
                    {isLoading ?
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <Spin />
                        </div>
                        : (
                            <>
                                {selectedTab === 1 && (
                                    <div style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                        <img
                                            src={profileImagePath ? process.env.REACT_APP_S3_BUCKET_URL + profileImagePath : Theme.images.img_placeholder}
                                            alt="icn_user_company"
                                            style={{ height: '60px', width: '60px', borderRadius: '50%' }}
                                        />
                                        <div className='details-and-meet-link mt-2'>
                                            <div className='row'>

                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("bussiness_name")}</p>
                                                    <p className='detail-value'>{businessName}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("person_name")}</p>
                                                    <p className='detail-value'>{personName}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("phone_number")}</p>
                                                    <p className='detail-value'>{phoneNumber}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("bussiness_address")}</p>
                                                    <p className='detail-value'>{businessAddress}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("web_url")}</p>
                                                    <p className='detail-value'>{websiteURL}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("email")}</p>
                                                    <p className='detail-value'>{email}</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                )}

                                {selectedTab === 2 && (
                                    <>
                                        {selectedCategories?.length !== 0 && <div className='row' style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                            {selectedCategories?.map((x: any, index: number) => (
                                                <div key={index} className='col-lg-2 mx-1 text-center p-2' style={{ backgroundColor: Theme.colors.white, borderRadius: '12px' }}>
                                                    <img src={x?.imagePath ? process.env.REACT_APP_S3_BUCKET_URL + x?.imagePath : Theme.icons.icn_link} alt='category_name' className="category-icon" />
                                                    <p className='inputfield-label m-0'>{x?.name}</p>
                                                </div>
                                            ))}
                                        </div>}

                                        <div className='row mt-3' style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                            <p className='user-name m-0'>{t("Service Technical Requirements")}</p>
                                            <p className='vendor-detail-description'>{serviceTechnicalDescription}</p>
                                        </div>
                                    </>
                                )}
                                {selectedTab === 3 && (

                                    <div style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                        <div className='w-75 row'>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Portfolio Link")}</p>
                                                <p className='detail-value'>{portfolioLink}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Social Media Link")}</p>
                                                <p className='detail-value'>{socialMediaLinks}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Experience")}</p>
                                                <p className='detail-value'>{experience}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Event Type Served")}</p>
                                                <p className='detail-value'>{eventType}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {selectedTab === 4 && (
                                    <>
                                        <div className='mt-3' style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>

                                            <p className='detail-label m-0'>{t("Available Week Days")}</p>
                                            <div className='d-flex'>
                                                {days?.length > 0 && days?.map(x => (
                                                    <div className='d-flex justify-content-center align-items-center m-2' style={{ height: '30px', width: '30px', backgroundColor: Theme.colors.primary, borderRadius: '50%' }}>
                                                        <p className='m-0' style={{ color: Theme.colors.white, fontSize: '10px', fontFamily: 'sf-regular' }}>{x}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='row w-75'>

                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("Booking Start Time")}</p>
                                                    <p className='detail-value'>{bookingStartTime}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("Booking End Time")}</p>
                                                    <p className='detail-value'>{bookingEndTime}</p>
                                                </div>
                                            </div>
                                            <p className='user-name m-0'>{t("Booking Process Details")}</p>
                                            <p className='vendor-detail-description'>{bookingProcessDetail}</p>
                                        </div>
                                    </>
                                )}
                                {selectedTab === 5 && (
                                    <>
                                        <div className='mt-3' style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                            <div className='row w-75'>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("Commercial Registration Number")}</p>
                                                    <p className='detail-value'>{commericalRegistrationNumber}</p>
                                                </div>
                                            </div>
                                            <p className='user-name m-0'>{t("Documents")}</p>
                                            <div className="row w-75">
                                                {vendorFreelancerCertifications?.map((file: any, index: number) => (
                                                    <div className="col-lg-6" key={index}>
                                                        <div className="d-flex align-items-center p-3 preview-image-view-container mt-3 cursor-pointer" onClick={() => file?.fileContentType !== "application/pdf" && previewImage(process.env.REACT_APP_S3_BUCKET_URL + file.path)}>

                                                            <div className="d-flex align-items-center">
                                                            { file?.fileContentType === "application/pdf" ?
    <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#EBE3F3', width: '60px', height: '60px', borderRadius: '12px' }}>
      <img src={Theme.icons.icn_document} />
    </div>
    :
                                                                <img
                                                                    src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                                                                    alt={file.originalFileName}
                                                                    className="category-icon mb-0"
                                                                    style={{ width: '60px', height: '60px', borderRadius: '12px' }}
                                                                />
                                                            }
                                                                <div className="mx-2">
                                                                    <p className="detail-label m-0">{file.originalFileName}</p>
                                                                    <p className="detail-label m-0">{(file.fileLength / 1024).toFixed(2)} Bytes</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {selectedTab === 6 && (

                                    <div style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                        <div className='w-75 row'>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Bank Name")}</p>
                                                <p className='detail-value'>{bankName}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Account Holder Name")}</p>
                                                <p className='detail-value'>{accountHolderName}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Account Number")}</p>
                                                <p className='detail-value'>{accountNumber}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("City")}</p>
                                                <p className='detail-value'>{cityName}</p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='detail-label m-0'>{t("Country")}</p>
                                                <p className='detail-value'>{countryName}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {selectedTab === 7 && (
                                    <>
                                        <div className='mt-3' style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                                            <div className='row w-75'>

                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("Contact Person")}</p>
                                                    <p className='detail-value'>{contactPersonName}</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <p className='detail-label m-0'>{t("Preferred Communication")}</p>
                                                    <p className='detail-value'>{communicationMethod}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                </div>
            </div>

<Modal
open={visible}
onCancel={() => setVisible(false)}
footer={null}
>
    <img src={previewImg} style={{width:'100%'}} />
</Modal>
            <AlertModal
                visible={isModalVisible}
                message={`Are you sure you want to ${switchChecked ? 'deactivate' : 'activate'} this vendor?`}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>
    );
};

export default ViewVendor;
