export const objectToFormData = (obj: any) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]: any) => {
    formData.append(key, value);
  });

  return formData;
};

export const getTheFirstTwoLetterOfString = (val: string) => {
  const store = val?.split(" ");
  if(store?.length === 1){
    return val?.substring(0, 2).toUpperCase();
  }
  const result = val?.trim()?.split(" ")?.filter(Boolean)?.map((part) => part[0])?.join("")?.toUpperCase();
    return result?.substring(0, 2);
};
