import React from 'react';
import { Tag } from 'antd'; // Assuming you're using Ant Design

type StatusConfig = {
  status: string;
  color: string;
  backgroundColor?: string;
  borderColor?: string;
  icon?: string;
  statusText?: string;
};

const statusConfig: StatusConfig[] = [
  { status: 'Pending', color: '#F95668', backgroundColor: '', borderColor: '#F95668' },
  { status: 'Reject', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },
  { status: 'Approved', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'VendorApproved', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'Paid', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'Success', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'Failed', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },

];

type StatusProps = {
  status: string;
};

const Status: React.FC<StatusProps> = ({ status }) => {
  const statusItem = statusConfig.find((item) => item.status === status);

  if (!statusItem) return null;

  return (
    <Tag
      color={statusItem.backgroundColor || 'transparent'} // Fallback to transparent if no background color is provided
      className="status-tag"
      style={{
        borderColor: statusItem.borderColor ? statusItem.borderColor : 'transparent',
        color: statusItem.color,
      }}
      title={statusItem.statusText || ''}
    >
      <span style={{
            backgroundColor: statusItem.color,
            width: '7px',
            height: '7px',
            borderRadius: '50%',
            display: 'flex',
            alignItems:'center',
            justifyContent:'center'
          }}></span>
      <span
        style={{
          color: statusItem.color,
        }}
      >
        {status}
      </span>
    </Tag>
  );
};

export default Status;
