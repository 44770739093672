import React, { useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import { Theme } from '../../../../Theme';
import VendorInformation from './component/VendorInformation';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VendorDetail from './component/VendorDetail';
import ServiceOffering from './component/ServiceOffering';
import PortfolioExperience from './component/PortfolioExperience';
import { useQuery } from 'react-query';
import { getVendorFreelancerById } from '../../../../services/freelancer';
import AvailabilityBookingDetail from './component/AvailabilityBookingDetail';
import CertificationsLicenses from './component/CertificationsLicenses';
import PaymentBillingInformation from './component/PaymentBillingInformation';
import SupportCommunication from './component/SupportCommunication';
import TermsAndConditions from './component/TermsAndConditions';

const VendorOnBoarding: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(1);
    const [maxVisitedTab, setMaxVisitedTab] = useState(1); // Track highest visited tab
    const [vendorDetails, setVendorDetails] = useState({});
    const [isPageRefreshed, setIsPageRefreshed] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const activeId = Number(searchParams.get('Id')) || 0;
    const isVendorDetailNotEmpty = Object.keys(vendorDetails).length > 0;
    const record = location?.state?.record || null;

    useEffect(() => {
        if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
            console.log("Page was refreshed!", activeId);
            setIsPageRefreshed(true);
            if (record) {
                console.log("Params id from record!", record?.id);
                setSearchParams({ Id: record?.id }, { replace: true });
            }
        }
    }, []);


    const { data: vendorData, isLoading, error } = useQuery(
        ['VendorFreelancerById', activeId],
        () => getVendorFreelancerById({ Id: activeId }), // Replace with your fetch function
        {
            enabled: isPageRefreshed && activeId > 0, // Query only runs if the page was refreshed and activeId exists
            onSuccess: (data) => {
                setVendorDetails(data.data); // Store vendor details after fetching
                setIsPageRefreshed(false);
                setMaxVisitedTab((data?.data as { tabNumber?: number })?.tabNumber || 0);
            },
        }
    );

    const isAddCase = activeId === 0 ? false : isLoading || !isVendorDetailNotEmpty;
    // const isEditCase = isLoading || !isVendorDetailNotEmpty;

    const FinalApprovals = [
        {
            id: 1,
            label: t("Vendor Information"),
            icon: Theme.icons.icn_user_company,
        },
        {
            id: 2,
            label: t("Profile & Technical Information"),
            icon: Theme.icons.icn_profile_technical,
        },
        {
            id: 3,
            label: t("service_offerings"),
            icon: Theme.icons.icn_services,
        },
        {
            id: 4,
            label: t("portfolio_experience"),
            icon: Theme.icons.icn_portfolio,
        },
        {
            id: 5,
            label: t("availability_booking"),
            icon: Theme.icons.icn_booking,
        },
        {
            id: 6,
            label: t("certifications_licenses"),
            icon: Theme.icons.icn_license,
        },
        {
            id: 7,
            label: t("payment_billing"),
            icon: Theme.icons.icn_payment,
        },
        {
            id: 8,
            label: t("support_communication"),
            icon: Theme.icons.icn_support,
        },
        {
            id: 9,
            label: t("terms_condition"),
            icon: Theme.icons.icn_terms,
        },
    ];

    const handleMoveToNext = (data: any) => {
        setSearchParams({ Id: data?.id }, { replace: true });
        setVendorDetails(data);
        const nextTab = selectedTab + 1;
        setSelectedTab(nextTab);
        setMaxVisitedTab(prev => Math.max(prev, nextTab)); // Update max visited tab
    };

    const handleTabClick = (tabId: number) => {
        if (tabId <= maxVisitedTab) {
            setSelectedTab(tabId);
        }
    };

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <img
                        src={Theme.icons.icn_arrow_back}
                        className='cursor-pointer'
                        alt="icn_user_add"
                        style={{ height: '20px' }}
                        onClick={() => navigate(-1)}
                    />
                    <p className='list-heading m-0 mx-2'>{t("Manage Vendor")}</p>
                </div>
                {/* <div className='d-flex align-items-center'>
                    <CButton style={{ marginRight: '12px', backgroundColor: Theme.colors.success, borderColor: Theme.colors.success }}>Accept</CButton>
                    <CButton className='rejectBtn' style={{ color: 'red' }}>Reject</CButton>
                </div> */}
            </div>
            <Card className='mt-3'>
                <p className='freelancer-heading m-0'>{t('Vendor Profile')}</p>
            </Card>
            <div className='row' style={{ paddingRight: '0.8rem' }}>
                <div className='col-lg-3'>
                    <Card className='mt-3' style={{ borderRadius: '16px' }}>
                        <p className='initial-approval m-0'>{t("Profile Details")}</p>
                        {FinalApprovals?.map((item) => (
                            <div
                                key={item.id}
                                className='d-flex align-items-center mt-2 w-100 approval-tab p-2'
                                style={{ border: item?.id <= maxVisitedTab ? `1px solid ${Theme.colors.tertiary}` : `1px solid ${Theme.colors.gray}` }}
                                onClick={() => handleTabClick(item.id)}
                            >
                                <div
                                    className='d-flex align-items-center justify-content-center'
                                    style={{
                                        height: '28px',
                                        width: '28px',
                                        borderRadius: '8px',
                                        backgroundColor: item?.id <= maxVisitedTab ? "#FDF0D9" : Theme.colors.gray_light
                                    }}
                                >
                                    <img
                                        src={item.icon}
                                        alt={item.label}
                                        style={{
                                            height: '20px',
                                            // Use filter to change the color of the SVG when selected
                                            filter: item?.id <= maxVisitedTab
                                                ? 'invert(74%) sepia(99%) saturate(1320%) hue-rotate(1deg) brightness(103%) contrast(101%)'
                                                : 'none'
                                        }}
                                    />
                                </div>
                                <p className='m-0 mx-2 admin'>{item.label}</p>
                            </div>
                        ))}
                    </Card>
                </div>
                <div className='col-lg-9 mt-3' style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px', }}>
                    {isAddCase ? <div className='d-flex justify-content-center align-items-center h-100'>
                        <Spin />
                    </div> :
                        <>
                            {selectedTab === 1 && <VendorInformation moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 2 && <VendorDetail moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 3 && <ServiceOffering moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 4 && <PortfolioExperience moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 5 && <AvailabilityBookingDetail moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 6 && <CertificationsLicenses moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 7 && <PaymentBillingInformation moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 8 && <SupportCommunication moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                            {selectedTab === 9 && <TermsAndConditions moveTo={handleMoveToNext} state={vendorDetails} isVendorDetailNotEmpty={isVendorDetailNotEmpty} selectedTab={selectedTab} maxVisitedTab={maxVisitedTab} />}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default VendorOnBoarding;
