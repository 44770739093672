import React, { useEffect, useState } from 'react';
import { Theme } from '../../Theme';
import { get } from '../../services/api/apiService';
import { useNavigate } from 'react-router-dom';

interface CustomAlertProps {
}

const Header: React.FC<CustomAlertProps> = ({  }) => {

  const navigate = useNavigate()
  const [notificationCount , setNotificationCount ] = useState(0);
  const [userDetails, setUserDetails] = useState<{ [key: string]: any }>({})

  const getNotificationCount = async ()=>{
  let notification = await get('AdminNotification/GetUnReadNotificationCount',{});
  console.log("notification.count",notification.data.count);
  
  setNotificationCount(notification.data.count);
  }

  const getProfile = ()=>{
    get('AdminAccount/GetMyProfile', {}).then(response => {
      if (response.succeeded) {
        setUserDetails(response.data);
      } 
    })
  }

  useEffect(()=>{
    getNotificationCount();
    getProfile();
  },[])

  return (
    <div className='p-3' style={{ backgroundColor: Theme.colors.white, display:'flex', justifyContent:'flex-end', alignItems:'center', paddingRight:10}}>
       <div className='notification-count' style={{ position: 'relative', display: 'inline-block' }}>
        <img
          alt=""
          src={Theme.icons.icn_notification}
          className="me-3"
          style={{ height: "36px", width:'36px',cursor:'pointer' }}

          onClick={()=>{
            setNotificationCount(0)
            navigate(`/jive/notifications`)
          }}
        />
        {notificationCount > 0 && <span className="notification-badge">
          {notificationCount}
        </span>}
      </div>
     <img
            alt=""
            src={Theme.images.img_placeholder}
            style={{ borderRadius:'50%', height:'44px', width:'44px', marginLeft:10 }}
          />
   <div>
   <p className="mx-2 m-0 detail-value">{userDetails?.fullName}</p>
   <p className='mx-2 m-0 admin'>{userDetails?.userType}</p>
   </div>
    </div>
  );
};

export default Header;
